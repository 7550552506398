.work-container {
    padding-top: 2rem;
    color: #F8F9FA;
    
}

/* Header Styles */
.section-title {
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 0.5px;
}

.hash-symbol {
    color: #C778DD;
    margin-right: 0.5rem;
}

.title-line {
    width: 50%;
    height: 2px;
    background-color: #C778DD;
    margin: 0 2rem;
}

.view-all-link {
    color: #F8F9FA;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease;
}

.view-all-link:hover {
    color: #C778DD;
}

/* Experience Section */
.experience-card {
    
    border-radius: 12px;
    padding: 2rem;
    height: 100%;
    border: 1px solid rgba(199, 120, 221, 0.2);
    transition: transform 0.3s ease, border-color 0.3s ease;
}

.experience-card:hover {
    transform: translateY(-5px);
    border-color: #C778DD;
}

.card-header {
    margin-bottom: 1.5rem;
}

.position-title {
    color: #C778DD;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.position-type {
    color: #F8F9FA;
    font-size: 1.2rem;
}

.company-info {
    color: #ADB5BD;
    font-size: 1rem;
}

.company-name {
    color: #ADB5BD;
    text-decoration: none;
    transition: color 0.3s ease;
}

.company-name:hover {
    color: #C778DD;
}

.separator {
    margin: 0 0.5rem;
    color: #6C757D;
}

.company-description {
    color: #ADB5BD;
    margin-bottom: 1.5rem;
    line-height: 1.6;
}

.experience-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.experience-list li {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
    position: relative;
    line-height: 1.8;
    color: #F8F9FA;
}

.experience-list li::before {
    content: "▹";
    position: absolute;
    left: 0;
    color: #C778DD;
}