
/* Styles for the button */
.contact {
    margin-top: 10px;
    background-color: transparent;
    color: white;
    font-weight: medium;
    font-size: 20px;
    padding: 4px 8px;
    border: 1px solid #c778dd;
    transition-duration: 200ms;
    cursor: pointer;
}

.contact:hover {
    background-color: #c778dd33;
}

.contact:active {
    background-color: #c778dd;
  
}

